.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* App.css */
.retro-bg {
  background-color: #000;
}

.Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.Nav-left .Logo {
  font-size: 24px;
  color: #fff;
  text-decoration: none;
}

.Nav-right ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.Nav-right ul li {
  display: inline-block;
  margin-left: 20px;
}

.Nav-right ul li:first-child {
  margin-left: 0;
}

.Nav-right ul li a {
  color: #fff;
  text-decoration: none;
}

.Home {
  padding: 20px;
  color: #fff;
}
