.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  cursor: url("pizza.png"), default;
  font-family: "Press Start 2P", system-ui;
  background-image: url("background.jpg");
  background-color: #000;
  overflow: hidden;
    height: 100%;
}
body {
  cursor: url("pizza.png"), default;
  background-image: url("background.jpg");
  background-color: #000;
  padding: env(safe-area-inset-top) 0 0;
  margin: 0;
  height: 100%;
    overflow: auto;
}

.Landing {
  height: 100vh;
}

.table-container {
  overflow-x: auto; /* Enable horizontal scrolling if necessary */
  max-width: 100%; /* Ensure table doesn't exceed screen width */
}

table { 
    width: 750px; 
    border-collapse: collapse; 
    margin:50px auto;
    }

/* Zebra striping */
tr:nth-of-type(odd) { 
    background: black; 
    color: #06A992;
    }
tr:nth-of-type(even) { 
    background: black; 
    color: #BB7805;
    }


.Event a {
  color: #BB7805;
}
.press-start-2p-regular {
  font-family: "Press Start 2P", system-ui;
  font-weight: 400;
  font-style: normal;
}

th { 
  font-family: "Press Start 2P", system-ui;
    background: black; 
    color: #FCF110; 
    font-weight: bold; 
    }

td, th { 
  font-family: "Press Start 2P", system-ui;
    padding: 10px; 
    border: 1px solid #ccc; 
    text-align: left; 
    font-size: 12px;
    }
h1 { 
      font-family: "Press Start 2P", system-ui;
      color: #F78402; 
      font-weight: bold;
      text-align: center;
      font-size: 18px;
  }
h2
  { 
    font-family: "Press Start 2P", system-ui;
    color: #FCF110; 
    font-size: 16px;
    text-align: center;
}
h3 { 
  font-family: "Press Start 2P", system-ui;
  color: #06A992;
  font-size: 12px;
  text-align: center;
}
h4 { 
  font-family: "Press Start 2P", system-ui;
  color: white;
  font-size: 10px;
  text-align: center;
}


.image-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Ensure container takes full height of the cell */
}

.small-image {
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
  margin-right: 5px; /* Adjust spacing between images */
}

p, li { 
  font-family: "Press Start 2P", system-ui;
  font-size: 10px;
  text-align: center;
}
a {
  font-family: "Press Start 2P", system-ui;
  font-size: 10px;
  text-align: center;
}

button {
  font-family: "Press Start 2P", system-ui;
  font-size: 10px;
  text-align: center;
  border: none;
  padding: 0;
  background: none;
  cursor: url("pizza.png"), default;
}
button:hover {
  background-color: #F78402;
}

 /* unvisited link */
 .contract-leaderboard a:link {
  color: #06A992;
}

/* visited link */
.contract-leaderboard a:visited {
  color: #06A992;
}

/* mouse over link */
.contract-leaderboard a:hover {
  color: #F036E1;
}

/* selected link */
a:active {
  color: #FF8702;
} 

  div.scrollable {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto;
}

.Token-Info {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 20px; /* Added padding */
  word-wrap: anywhere; /* Allow text to wrap */
  font-family: "Noto Sans", sans-serif;
}

.Token-Info p {
  margin: 5px 0;
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
}

.Token-Info .header {
  color: #3575FF; /* Header color */
  font-weight: bold;
}

.Token-Info .value {
  color: white; /* Value color */
}

.dextools {
  padding: 0 20px; /* Added padding */
  word-wrap: anywhere; /* Allow text to wrap */

}

.Token-Info p {
  padding: 0 20px; /* Added padding */
  word-wrap: anywhere; /* Allow text to wrap */
}

/* Media queries for responsive design */
@media only screen and (max-width: 768px) {
  .Token-Info {
    text-align: center; /* Center the content on mobile devices */
  }

}


/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/


@media screen and (max-width: 600px) {
  table {
    width: calc(100% - 10px); /* Adjusted width for smaller screens */
  }
  th,
  td {
    padding: 5px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 480px) {
  /* horizontal scrollbar for tables if mobile screen */
  .tablemobile {
      overflow-x: auto;
      display: block;
  }
}

/* Adjust table width to fit smaller screens */
@media screen and (max-width: 600px) {
table {
  width: 100%; /* Change width to 100% to fit smaller screens */
}
}

/* Add horizontal scrollbar for tables on mobile screens */
@media only screen and (max-width: 480px) {
.tablemobile {
  overflow-x: auto;
  display: block;
}
}

/* Adjust cell padding and font size for smaller screens */
@media only screen and (max-width: 480px) {
td, th {
  padding: 5px;
  font-size: 7px; /* Decrease font size for smaller screens */
}
}

/* Ensure content within cells is scrollable */
td {
white-space: nowrap; /* Prevent line breaks within cells */
overflow: auto; /* Add scrollbar if content overflows cell */
}

/* Adjust cell width to fit content */
@media only screen and (max-width: 600px) {
td, th {
  max-width: 100px; /* Set maximum width for cells */
  overflow: hidden; /* Hide content that overflows maximum width */
  text-overflow: ellipsis; /* Add ellipsis for truncated content */
}
}



/* App.css */
.retro-bg {
  
  background-image: url("background.jpg");
}

.Dashboard {
  padding: 20px;
}

.Dashboard p {
  color: orange;
  font-family: "Noto Sans", sans-serif;
    font-size: 12px;
}
.Dashboard h3 {
  font-family: "Noto Sans", sans-serif;
    font-size: 16px;
}


.Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-image: url("background.jpg");
  padding: 10px 20px;
}

.Nav-left .Logo {
  font-size: 24px;
  color: #F78402;
}

.Nav-right ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.Nav-right ul li {
  display: inline-block;
  margin-left: 20px;
}

.Nav-right ul li:first-child {
  margin-left: 0;
}

.Nav-right ul li a {
  color: #F78402;
  text-decoration: underline;
}

.Nav-left img {
  width: 50px; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
}

.Hero {
  text-align: center;
  margin-bottom: 20px; /* Add some space below the hero image */
}

.Hero img {
  width: 25%; /* Ensure the image fills the container */
  max-width: 800px; /* Limit the maximum width of the image */
  height: auto; /* Maintain aspect ratio */
}

.Hero-Full {
  text-align: center;
}

.Hero-Full img {
  width: 100%; /* Ensure the image fills the container */
  height: auto;
}

.news {
  margin-bottom: 10px;
  margin-top: 10px;
}

.news img {
  max-width: 500px;
  height: auto;
  }
  @media screen and (max-width: 480px) and (min-width: 320px) {
      /* For mobile phones: */
  * {
      box-sizing: border-box;
  }
  .news img {
  width: 100%;
  height: auto;
  } }

  .container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.moving-gif {
  position: absolute;
  left: -100px; /* Start outside of the viewport */
  display: none; /* Hide by default */
  padding-bottom: 400px;
  z-index: 0;
}

@media only screen and (max-width: 768px) {
  .moving-gif {
      display: block; /* Show on mobile screens */
      animation: moveAndGrow 10s linear infinite alternate; /* Infinite loop with alternate direction */
      z-index: 0;
  }
}

@keyframes moveAndGrow {
  0% {
      left: -100px; /* Start outside of the viewport */
      transform: scale(1); /* Original size */
  }
  50% {
      left: calc(100% - 100px); /* End position at the right */
      transform: scale(2); /* Twice the original size */
  }
  100% {
      left: -100px; /* Return to start position */
      transform: scale(1); /* Original size */
  }
}

.Home-Header h1 {
  font-weight: 400;
  font-size: 6vw;
}

.Home-Header p{
  font-size: 14px;
  font-family: "Noto Sans", sans-serif;
}

.Home-Header h4{
  font-size: 16px;
  font-family: "Noto Sans", sans-serif;
  
}

.rainbow-text {
  font-weight: bold;
  /* Optional: Add more styles */
}

.Hero-Small {
  text-align: center;

}

.Hero-Small img {
  width: 25%; /* Ensure the image fills the container */
  max-width: 200px; /* Limit the maximum width of the image */
  height: auto; /* Maintain aspect ratio */
}

.Home {
  padding: 20px;
  color: #fff;

  background-image: url("background.jpg");
}

.Home, .Social-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  background-image: url("background.jpg");
}

.How-to-buy,
.Listings,
.Home-Header {
  margin-top: 20px;
  margin-bottom: 20px;
}

.Footer {

  background-image: url("background.jpg");
}

.Listings {
  margin-top: 20px;
  margin-bottom: 20px;
}


.Listings p {
  font-size: 16px;
  margin-bottom: 10px;
}

.listing-images {
  display: flex;
  justify-content: space-between; /* Distribute items evenly */
}

.listing-images a {
  flex: 1; /* Each image takes equal space */
  margin-right: 20px; /* Add spacing between images */
}

.listing-images img {
  width: 100%; /* Ensure images fill the container */
  max-width: 100px;
  height: auto; /* Maintain aspect ratio */
}

.chain-images {
  display: flex;
  justify-content: space-between; /* Distribute items evenly */
}

.chain-images a {
  flex: 1; /* Each image takes equal space */
  margin-right: 20px; /* Add spacing between images */
}

.chain-images img {
  width: 100%; /* Ensure images fill the container */
  max-width: 100px;
  height: auto; /* Maintain aspect ratio */
}


/* Center the button in the How-to-buy section */
.How-to-buy button,
.Call-to-action button {
  margin-top: 20px; /* Add some space above the buttons */
  
}
.How-to-buy a{
  font-family: "Noto Sans", sans-serif;
    font-size: 14px;
  
}

.How-to-buy {
  text-align: center;
}

.buy-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.buy-button {
  background-color: #3575FF;
  color: white;
  max-width: 200px;
  padding: 10px 20px;
  margin: 10px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  width: calc(50% - 20px); /* Make both buttons have equal width */
}

.buy-button:hover {
  background-color: #F78402;
}

@media (max-width: 768px) {
  .buy-button {
    width: 100%; /* Full width on mobile */
    margin: 10px 0; /* Margin between the buttons */
  }
  .chain-images img {
    width: 100%; /* Ensure images fill the container */
    max-width: 50px;
    height: auto; /* Maintain aspect ratio */
  }
  .Token-Info span{
    padding-right: 5px;
  }
}

.Timeline-Content p{
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
}
.Timeline-Content h1{
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
}
.Timeline-Content h2{
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
}
.Timeline-Content a{
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
}


.Timeline {
  margin-top: 20px;
  margin-bottom: 20px;
}


.Timeline ul {
  list-style: none;
  padding: 0;
}

.Timeline li {
  display: flex;
  margin-bottom: 20px;
}

.Date {
  font-weight: bold;
  margin-right: 20px;
  color: #3575FF; /* Blue color */
}


/* Container for the game */
.start-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.game-container {
  border: 5px solid #fff;
  max-width: 100%;
  width: 320px;
  height: 480px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  background-image: url('https://gaccdiscordimages.s3.amazonaws.com/fb_start_bg.png');
}

.game-over-footer {
  margin-top: 20px;
}

.game-over-footer p{
  font-size: 8px;
  color: #F036E1;
}

/* Canvas for the game */
canvas {
  display: block;
  width: 100%;
  height: 100%;
}

/* Modal styles */
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.Social-links {
  display: flex;
  margin-top: 20px;
}

.Social-links a {
  margin-right: 10px;
}

.Social-links img {
  width: 24px; /* Adjust size as needed */
  height: 24px;
}

.social-images {
  display: flex;
  justify-content: space-between; /* Distribute items evenly */
}

.social-images a {
  flex: 1; /* Each image takes equal space */
  margin-right: 20px; /* Add spacing between images */
}

.social-images img {
  width: 100%; /* Ensure images fill the container */
  max-width: 50px;
  height: auto; /* Maintain aspect ratio */
}


/* MEME */
.meme-p p {
  color: #FCF110; 
}


.Nav-left a {
  color: white;
  display: flex;
  align-items: center;
}

.Nav-left p {
  padding-left: 10px; 
  font-size: 20px;
}

@media screen and (max-width: 600px) {
  .Nav-left p {
    visibility: hidden;
    display: none;
  }
}

.Nav-left a:link {
  text-decoration: none;
  
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 2px solid #000;
}

.input-area {
  text-align: center;
}

.input-area input[type="file"] {
  border: 1px solid #000;
  padding: 5px;
  margin-bottom: 10px;
}

#imageInput {
  color: white;
}

#imageCanvas {
  max-width: 400px; /* Constrain the canvas width */
  max-height: 400px; /* Constrain the canvas height */
  border: 1px solid #000; /* Optional: add a border for visibility */
  margin-top: 10px;
}

.history-body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: white;
}

.history-body h3 {
  font-family: Arial, sans-serif;
  font-size: 20px;
}

.history-body p {
  font-family: Arial, sans-serif;
  font-size: 17px;
}

.history-body a {
  font-family: Arial, sans-serif;
  font-size: 17px;
}

.history-body button {
  font-family: Arial, sans-serif;
  font-size: 17px;
}


.textbox {
  padding: 20px;
  text-align: center;
  background-color: #f5f5f5;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
}

.grid-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15px, 1fr));
  gap: 2px;
  width: 100%;
  max-width: 1200px; /* Ensures the grid doesn’t get too wide */
  box-sizing: border-box;
}

.grid-item,
.year-cell {
  width: 100%;
  padding-bottom: 100%; /* Ensures the cells are square */
  position: relative; /* For absolute positioning of images */
  background-color: #ddd;
  border-radius: 2px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden; /* Prevent overflow of image outside cell */
}

.year-cell {
  background-color: #333;
  color: white;
  font-size: 17px; /* Standardize the font size */
  line-height: 1;
}

.grid-item img,
.year-cell img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensures images fit within the grid cell */
  border-radius: 2px;
}

/* Sidebar styling */
.sidebar {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  font-family: Arial, sans-serif;
  height: 100%;
  background-color: #333;
  color: white;
  overflow-y: auto;
  padding: 20px;
  transition: right 0.3s ease;
  z-index: 1000;
  box-sizing: border-box;
}

.sidebar.open {
  right: 0;
}

/* Ensures long words break and wrap to the next line */
.sidebar-content {
  padding: 10px;
  font-family: Arial, sans-serif;
  word-wrap: break-word; /* Added */
  overflow-wrap: break-word; /* Added */
  color: white; /* Ensure all text inside the sidebar is white */
}

.sidebar-content img {
  width: 100%;
  height: auto;
  max-width: 100px;
  max-height: 100px;
  object-fit: contain;
  margin-bottom: 10px;
  margin-top: 30px;
}

.close-btn {
  background-color: #444;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 20px;
}

/* Leaderboard styling with a consistent color scheme */
.leaderboard {
  margin: 20px auto;
  padding: 10px;
  max-width: 600px;
  background-color: #f5f5f5;
  font-family: Arial, sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  box-sizing: border-box;
  color: #333;
}

.leaderboard h2 {
  margin-bottom: 15px;
  color: #333;
}

.leaderboard table {
  width: 100%;
  border-collapse: collapse;
  color: #333;
}

.leaderboard th, .leaderboard td {
  padding: 10px;
  border: 1px solid #ddd;
}

.leaderboard th {
  background-color: #444;
  color: white;
}

.leaderboard td {
  background-color: #fff;
  color: #333;
}

footer {
  text-align: center;
  padding: 20px;
  background-color: #f5f5f5;
  font-size: 0.9rem;
  background-color: #f5f5f5;
} 

/* Responsive design */
@media (max-width: 768px) {
  .grid-container {
      grid-template-columns: repeat(auto-fill, minmax(15px, 1fr));
  }

  .grid-item,
  .year-cell {
      padding-bottom: 100%; /* Keep square ratio on smaller screens */
  }

  .sidebar {
      width: 80%;
  }

  .leaderboard {
      max-width: 90%;
  }
}

.contribute-form button {
  background-color: #444;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 4px;
  width: calc(50% - 10px);
}

.contribute-form button:hover {
  background-color: #333;
}

.sidebar-content hr {
  border: 0;
  border-top: 1px solid #ddd;
  margin: 15px 0;
}

.sidebar-content button {
  background-color: #444;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
}

.sidebar-content button:hover {
  background-color: #333;
}

--
/* Make the textboxes wider in the form */
.sidebar-content input[type="text"],
.sidebar-content input[type="url"] {
  width: 100%; /* Take up the full width of the sidebar */
  padding: 8px;
  margin-bottom: 10px;
  box-sizing: border-box; /* Ensures padding is included in the width */
  font-size: 10px; /* Adjust the font size as needed */
}

/* Styling for the form labels */
.sidebar-content label {
  display: block;
  font-size: 12px; /* Make the label font smaller */
  color: white;
  margin-bottom: 5px; /* Add some space between label and textbox */
}

/* Ensure consistent link color in the sidebar and across the page */
.sidebar-content a,
a {
  color: #0066cc; /* Set to a consistent color (adjust to your theme) */
  text-decoration: none; /* Remove underline */
}

/* Prevent color change on click */
.sidebar-content a:visited {
  color: #0066cc; /* Same color as regular links */
}

/* Optional: Add a hover effect for better UX */
.sidebar-content a:hover {
  color: #004999; /* Darker shade for hover */
}

/* Update Contribute Add Project button style */
.contribute-btn {
  background-color: #444;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 20px;
  border-radius: 4px;
  width: 100%;
}

.contribute-btn:hover {
  background-color: #333;
}